import Custom from '@/modules/virtual-museum-service/store/custom'

const stateVars = {
  commonObj: {
    hasDropdownLoaded: false,
    perPage: 10,
    dateFormat: 'dd/mm/YYYY',
    timeFormat: 'h:m',
    loading: false,
    listReload: false,
    test: [],
    informationTypeList: [],
    cropTypeList: [],
    cropList: [],
    characteristicsTypeList: [],
    characteristicsList: [],
    soilTypeList: [],
    insectList: [],
    diseaseList: [],
    tourOperator: [],
    pestList: [],
    organizationProfileList: [],
    pestType: Custom.pestType
  }
}

export default stateVars
